import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Text, Section, Flex, Subhead,Heading, PlatformHeading, PlatformSubhead } from "../ui"
import { StaticImage } from "gatsby-plugin-image"
import platform_banner from '../../images/platform_images/platform_banner.png'
import { about_hero, about_hero_img } from "../ui.css"

export default function Why_skill_elevator_hero(props) {
    return (
        <>
        <Section className={about_hero}>
        <Container  width="tight" >
            <Flex gap={4} variant="responsive">
                <Box>
                <PlatformHeading>
                Why Skill-Elevator
          </PlatformHeading>
          <PlatformSubhead>
          The best platform for commerce skilling & job enhancement. 

          </PlatformSubhead>
         
                </Box>
            </Flex> 
         
        </Container>
      </Section>
      <Section></Section>
     
          <Container className={about_hero_img}>
               <StaticImage
            src='../../images/why_skill_elevator_images/why_skill_elevator_banner.png' alt="why_skill_elevator_banner" width={1000}/></Container>
    
      </>
    )
}
