import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Why_skill_elevator_hero from "../components/about_whyskill_elevator/why_skill_elevator_hero"
import Industy_challanges from "../components/about_whyskill_elevator/Industry_challangs"
import Step_taken from "../components/about_whyskill_elevator/steps_taken"
import Would_help from "../components/about_whyskill_elevator/would_help"
import AboutNavList from "../components/about/about_nav_items"

const WhyBizLabPage = () => (
    <Layout navName="About">
        <Seo title="About" />
        <AboutNavList navName="why_BizLab" />
        <Why_skill_elevator_hero />
        <Industy_challanges />
        <Step_taken />
        <Would_help />
    </Layout>
)
export default WhyBizLabPage
